import { render, staticRenderFns } from "./InputViewTenanciesColumnGroup.vue?vue&type=template&id=0a73d7c1"
import script from "./InputViewTenanciesColumnGroup.vue?vue&type=script&setup=true&lang=ts"
export * from "./InputViewTenanciesColumnGroup.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./InputViewTenanciesColumnGroup.vue?vue&type=style&index=0&id=0a73d7c1&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,Label: require('/app/node_modules/@align/ui/dist/runtime/molecules/Label/Label.vue').default,KeyValuePair: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,TickBox: require('/app/node_modules/@align/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,Accordion: require('/app/node_modules/@align/ui/dist/runtime/molecules/Accordion/Accordion.vue').default})
