// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_5ahFG{display:flex;flex-direction:row;gap:1rem}.label_\\+JeXw{display:flex;margin:0 2rem;transform:translateY(.1rem)}.pageLabel_o3Wrb{align-self:center}.pageLabel_o3Wrb:hover{background-color:var(--color-accent-100)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_5ahFG",
	"label": "label_+JeXw",
	"pageLabel": "pageLabel_o3Wrb"
};
module.exports = ___CSS_LOADER_EXPORT___;
