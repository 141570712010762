// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/Align_Logo_Fade.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_NW0Zy{display:inline;position:relative}.loader_z7nlN{height:1rem;left:0;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-position:center center;mask-position:center center;-webkit-mask-repeat:no-repeat;mask-repeat:no-repeat;-webkit-mask-size:contain;mask-size:contain;position:absolute;top:0;transform:translateZ(0);width:1rem}.loader_z7nlN.large_9gCgT{height:1.75rem;width:1.75rem}.slot_QQWn1.loading_ibk7w{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_NW0Zy",
	"loader": "loader_z7nlN",
	"large": "large_9gCgT",
	"slot": "slot_QQWn1",
	"loading": "loading_ibk7w"
};
module.exports = ___CSS_LOADER_EXPORT___;
